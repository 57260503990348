export enum ContatosTelefonesEnum {
  DAYANEWHATSAPP = '(11) 99111-6583',
  DAYANEWHATSAPPDDI = '+55 11 99111 6583',
  SAC = '0800 775 0500',
  CAC = '0300 111 0500',
  DESBLOQUEIOCARTAO = '0800 208 1372',
  CACDEFICIENTEAUDITIVO = '0800 775 2005',
  OUVIDORIA = '0800 777 0900',
  DAYCONNECTSUPTEC = '0300 777 2020',
  DAYCONNECTFRAUDES = '(11) 3138-0531',
  SACCARTOES = '0800 880 6158',
  CENTRALATENDIMENTO = '0300 1110 500',
  CACCARTOESCAPITAISEREGIOSMETROPOLITANASMAISINFO = '3004-5300 (Capitais e regiões metropolitanas)',
  CACCARTOESCAPITAISEREGIOSMETROPOLITANAS = '3004-5300',
  CACCARTOESDEMAISLOCALIDADESINFO = '0800 721 5300 (Demais localidades)',
  CACCARTOESDEMAISLOCALIDADES = '4090 2223',
  CARTAOBLACKMETROPOLCOMINFO = '3003-5350 (Capitais e regiões metropolitanas)',
  CARTAOBLACKMETROPOL = '3003-5350',
  CARTAOBLACKCOMINFO = '0800 723 5350 (Demais localidades)',
  CARTAOBLACK = '0800 723 5350',
  ATENDIMENTODEFICIENTEAUDITIVOCARTOES = '0800 880 1919',
  VENDASCARTOESNAOCONSIG = '(11) 2344-0101 ou WhatsApp (11) 99111-6583',
  VENDASCARTOESCONSIG = '0300 111 0500 Menu: Cartão de Crédito: opção 1',
  COBRANCAIFP = '0800 777 2008',
  VENDASIFP = '0300 344 1000 ',
  ABRECONTAS = '0300 111 0500 Menu: Investimentos: opção 3',
  CAMBIO = '0300-111-2009',
  CREDITODIGITALWHATSAPP = '(11) 99111-6583',
  INVESTIMENTOS = '(11) 3138-1201',
  AGENCIAMATRIZ = '(11) 3138-0500',
  REMESSAATIVA1 = '(11) 3138-0465',
  REMESSAATIVA2 = '(11) 3138-0916',
  IMPRENSA = '(11) 3138-1217',
  ASSET = '0300 111 0500 / 0300 777 2020',
  MERCADOCAPITAIS = '(11) 3138 1623',
  DAYCOVALDIGITAL = '(11) 3138-0540',
  SUPTECNICO = '0300 777 2020 ou  11 3138-0531',
  RELACOESCLIENTES = '0300 111 0500 ou 4090-2223'
}
